import {dispatchTrackEvent} from '@mobile-de/trackking';
import {GtmEvent} from "../tracking/trackking/initTrackking";

const passwordForgottenLink = document.getElementById("passwordForgottenLink");

const setParamsToPath = () => {
  const params = new URLSearchParams(window.location.search);
  if (!params.has("lang")) {
    params.set("lang", "de");
  }

  const queryParams = params.toString(); // Get the current query string
  const newParams = queryParams ? `?${queryParams}` : "";
  passwordForgottenLink.href = `https://login.mobile.de/a2/request_reset_password${newParams}`;
};

const handlePasswordForgottenLinkClick = () => {
  dispatchTrackEvent(GtmEvent.PASSWORD_RESET_NAV);
};

export const setupForgottenPasswordFlow = () => {
  passwordForgottenLink.onclick = handlePasswordForgottenLinkClick;
  setParamsToPath();
};
