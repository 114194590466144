export const getConfig = () => JSON.parse(document.querySelector("#data").innerHTML);
export const getLoginFormErrors = () => {
  const formError = document.getElementById("hasFormError");

  if (formError) {
    return JSON.parse(formError.innerHTML);
  }

  return undefined;
};
export const getTanVerificationErrors = () => {
  const formError = document.getElementById("hasTanFormError");

  if (formError) {
    return JSON.parse(formError.innerHTML);
  }

  return undefined;
};
export const getLoginMailInput = () => document.getElementById("login-username");
export const getLoginSubmit = () => document.getElementById("login-submit");
export const getRememberMeInput = () => document.getElementById("remember-me");
export const getLoginPromo = () => document.getElementById("login-promo-container");
export const getRegisterNow = () => document.getElementById("register-now");
export const getTanSubmit = () => document.getElementById("tan-login-submit");
export const getTanNumbers = () => document.getElementById("phone-numbers");
export const getChoosePhoneSection = () => document.getElementById("mfa-choose-phone");
export const getMfaOtpTanVerificationSection = () => document.getElementById("casSimpleMfaLoginView");
export const getMultiloginAccountSelection = () => document.getElementById("casSelectAccount");
export const getTanTokenInput = () => document.getElementById("token");
export const getTanTokenSubmitButton = () => document.getElementById("otp-submit");
export const getTanTokenCorrectionButton = () => document.getElementById("correction-button");
export const getHiddenTokenInput = () => document.getElementById("hidden-token");
export const getTanTokenSubmitForm = () => document.getElementById("otpSubmitForm");
export const getOtpButtons = () => document.getElementsByClassName("otp-button");

