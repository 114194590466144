import {
  getHiddenTokenInput,
  getOtpButtons,
  getTanTokenCorrectionButton,
  getTanTokenInput,
  getTanTokenSubmitButton,
  getTanTokenSubmitForm
} from "../config/commonGetters";
import {dispatchTrackEvent} from "@mobile-de/trackking";
import {GtmEvent} from "../tracking/trackking/initTrackking";

const MAX_TOKEN_LENGTH = 6;

export const setupTanVerificationFlow = () => {
  const tokenInput = getTanTokenInput();
  const otpSubmitButton = getTanTokenSubmitButton();
  const correctionButton = getTanTokenCorrectionButton();

  otpSubmitButton.addEventListener("click", function () {
    const hiddenToken = getHiddenTokenInput();
    const token = getTanTokenInput();
    const submitForm = getTanTokenSubmitForm();

    hiddenToken.value = token.value;
    otpSubmitButton.disabled = true;

    dispatchTrackEvent(GtmEvent.TAN_VALIDATION_ATTEMPT);
    submitForm.submit();
  });

  correctionButton.onclick = function (event) {
    event.preventDefault();
    const input = getTanTokenInput();
    input.value = input.value.slice(0, -1);
  }

  const buttons = getOtpButtons();
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("click", function (event) {
      event.preventDefault();
      const value = this.textContent;

      if (String(tokenInput.value.length) >= MAX_TOKEN_LENGTH) {
        // If the input has reached the maximum length, prevent further input
        return;
      }

      tokenInput.value += value;
    });
  }

  function toggleButtonState() {
    if (tokenInput.value.trim() !== "") {
      otpSubmitButton.removeAttribute("disabled");
    } else {
      otpSubmitButton.setAttribute("disabled", "disabled");
    }
  }

  tokenInput.addEventListener("input", toggleButtonState);
}
