import {defaultHeaders} from "./defaultHeaders";

export const LS_USER_NAME_KEY = 'username';

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const expires = date.toUTCString();
  document.cookie = `${name}=${value};path=/;expires=${expires}`;
};

export const getCookie = (name) => {
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    if (ca[i].includes(name)) {
      return ca[i].split('=')[1];
    }
  }

  return null;
};

export const removeCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export const getLocalStorageItemByKey = (key) => {
  return localStorage.getItem(key);
}

export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, value);
}

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
}

export const setDefaultLanguage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang");

  if (![undefined, null].includes(lang)) {
    defaultHeaders.append("accept-language", lang);
  }
};
