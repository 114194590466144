export const GLOBAL_STATE_KEYS = {
  EMAIL: "username",
  USER_ID: "userId",
  CLIENT_ID: "clientId",
}

const _state = {
  [GLOBAL_STATE_KEYS.EMAIL]: "",
  [GLOBAL_STATE_KEYS.USER_ID]: undefined,
  [GLOBAL_STATE_KEYS.CLIENT_ID]: undefined,
};

export const setEmail = (email) => { _state[GLOBAL_STATE_KEYS.EMAIL] = email };

export const getUserId = () => _state[GLOBAL_STATE_KEYS.USER_ID];

export const getClientId = () => _state[GLOBAL_STATE_KEYS.CLIENT_ID];

export const setVi = ({ userId, clientId}) => {
  _state[GLOBAL_STATE_KEYS.USER_ID] = userId;
  _state[GLOBAL_STATE_KEYS.CLIENT_ID] = clientId
}
