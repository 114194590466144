import {setEmail} from "../state/globalState.js";
import {
  getLoginMailInput,
  getLoginPromo,
  getLoginSubmit,
  getRegisterNow,
  getRememberMeInput
} from "../config/commonGetters.js";
import {
  getCookie,
  getLocalStorageItemByKey,
  LS_USER_NAME_KEY,
  removeCookie,
  removeLocalStorageItem,
  setCookie,
  setLocalStorageItem
} from "../config/helpers";
import {dispatchTrackEvent} from '@mobile-de/trackking';
import {GtmEvent} from "../tracking/trackking/initTrackking";

const login = getLoginSubmit();
const loginMailInput = getLoginMailInput();
const loginSubmit = getLoginSubmit();
const rememberMeInput = getRememberMeInput();
const loginPromo = getLoginPromo();
const registerNow = getRegisterNow();

const handleLoginButtonClick = () => {
  handleRememberMe();
  dispatchTrackEvent(GtmEvent.LOGIN_ATTEMPT);
};

const handleInputStateUpdate = (event) => {
  setEmail(event.target.value);
};

export const handleRememberMe = () => {
  const username = getLoginMailInput().value;

  if (rememberMeInput.checked) {
    setLocalStorageItem(LS_USER_NAME_KEY, username);
    setCookie(LS_USER_NAME_KEY, username, 365);
  } else {
    removeLocalStorageItem(LS_USER_NAME_KEY);
    removeCookie(LS_USER_NAME_KEY);
  }
}

const handleLoginPromo = () => {
  dispatchTrackEvent(GtmEvent.PROMO_NAV);
}

const handleRegisterNow = () => {
  dispatchTrackEvent(GtmEvent.REGISTRATION_NAV);
}

const setRememberMe = () => {
  const storedUserNameFromLocalStorage = getLocalStorageItemByKey(LS_USER_NAME_KEY)
  const storedUserNameFromCookie = getCookie(LS_USER_NAME_KEY);

  if (storedUserNameFromLocalStorage || storedUserNameFromCookie) {
    const loginMailInput = getLoginMailInput();
    loginMailInput.value = storedUserNameFromLocalStorage || storedUserNameFromCookie;
    const rememberMeInput = getRememberMeInput();
    rememberMeInput.checked = true;
  }
};

export const setupLoginFlow = () => {
  setRememberMe();
  loginSubmit.onclick = handleLoginButtonClick;
  loginMailInput.oninput = handleInputStateUpdate;
  rememberMeInput.onchange = handleRememberMe;
  loginPromo.onclick = handleLoginPromo;
  registerNow.onclick = handleRegisterNow;

  login.addEventListener("submit", function () {
    loginSubmit.disabled = true;
  });
};
