import {defaultHeaders} from "../config/defaultHeaders.js";
import {setVi} from "../state/globalState";
import {GtmEvent, initTrackking, PageType} from "../tracking/trackking/initTrackking";
import {dispatchTrackEvent} from "@mobile-de/trackking";
import {getLoginFormErrors, getMfaOtpTanVerificationSection, getTanVerificationErrors} from "../config/commonGetters";

const viRoute = "https://www.mobile.de/api/vi/plain";
const MAX_VI_RETRIES = 3;
let viCalledTimes = 0;

export const setupTracking = async () => {
  viCalledTimes++;

  try {
    const response = await fetch(viRoute, {
      method: "GET",
      credentials: "include",
      headers: defaultHeaders,
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`)
    }

    const data = await response.json();

    if (data) {
      setVi({clientId: data?.cid, userId: data?.sub});
      initTrackking();

      const isMfaOtpTanVerificationTemplate = getMfaOtpTanVerificationSection();
      const pageType = isMfaOtpTanVerificationTemplate ? PageType.TAN_VALIDATION : PageType.LOGIN

      dispatchTrackEvent(GtmEvent.PAGE_VIEW, {pageType});

      const loginForm = getLoginFormErrors();
      const tanFormError = getTanVerificationErrors();

      if (loginForm?.hasFormError) {
        dispatchTrackEvent(GtmEvent.LOGIN_ERROR);
      }
      if (tanFormError?.hasTanFormError) {
        dispatchTrackEvent(GtmEvent.TAN_VALIDATION_ERROR)
      }
    }
  } catch (error) {
    if (viCalledTimes < MAX_VI_RETRIES) {
      setTimeout(() => {
        setupTracking();
      }, 2000 * viCalledTimes);
    }
  }
};
