import {setupTracking} from "./setup/setupTracking.js";
import {setupForgottenPasswordFlow} from "./setup/setupForgottenPasswordFlow.js";
import {setupLoginFlow} from "./setup/setupLoginFlow.js";
import {setupTanFlow} from "./setup/setupTanFlow";
import {
  getChoosePhoneSection,
  getMfaOtpTanVerificationSection,
  getMultiloginAccountSelection
} from "./config/commonGetters";
import {setupTanVerificationFlow} from "./setup/setupTanVerificationFlow";
import {setDefaultLanguage} from "./config/helpers";
import {setupMultiAccounts} from "./setup/setupMultiAccount";

setDefaultLanguage();
await setupTracking();

const isMfaTemplate = getChoosePhoneSection();
const isMfaOtpTanVerificationTemplate = getMfaOtpTanVerificationSection();
const isMultiloginAccountTemplate = getMultiloginAccountSelection();

if (isMfaTemplate) {
  setupTanFlow();
} else if (isMfaOtpTanVerificationTemplate) {
  setupTanVerificationFlow();
} else if (isMultiloginAccountTemplate) {
  setupMultiAccounts();
} else {
  setupLoginFlow();
  setupForgottenPasswordFlow();
}

