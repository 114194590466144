const multiloginAccounts = document.querySelectorAll(".multi-login-account-item");

export const setupMultiAccounts = () => {
  multiloginAccounts.forEach((element, index) => {
    element.addEventListener("click", () => {
      const radioSelectorButton = document.getElementById(`select-account-${index}`)
      radioSelectorButton.click();
      document.getElementById("select-account-form").submit();
    });
  });
};
