import {getTanNumbers, getTanSubmit} from "../config/commonGetters";

const tanSubmit = getTanSubmit();
const phoneNumbers = getTanNumbers();

const handleTanButtonDisabled = () => {
  tanSubmit.removeAttribute("disabled");
}

export const setupTanFlow = () => {
  phoneNumbers.onclick = handleTanButtonDisabled;
}
