import {initialize} from "@mobile-de/trackking";
import GoogleTagManager, {trackPageMeta, trackPageView} from '@mobile-de/trackking/services/google-tag-manager';
import {getClientId, getUserId} from "../../state/globalState";
import {getConfig} from "../../config/commonGetters";

export const GA_PROPERTY = 'Desktop';
const STAGING_ENV = 'staging';
const PRODUCTION_ENV = 'production';

export const GtmEvent = {
  PAGE_VIEW: 'PAGE_VIEW',
  LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
  LOGIN_ERROR: 'LOGIN_ERROR',
  TAN_VALIDATION_ATTEMPT: 'TAN_VALIDATION_ATTEMPT',
  TAN_VALIDATION_ERROR: 'TAN_VALIDATION_ERROR',
  PASSWORD_RESET_NAV: 'PASSWORD_RESET_NAV',
  REGISTRATION_NAV: 'REGISTRATION_NAV',
  PROMO_NAV: 'PROMO_NAV',
};

export const PageType = {
  LOGIN: 'DealerLoginPage',
  TAN_VALIDATION: 'DealerTANCodePage',
};

export const eventsMap = {
  [GtmEvent.PAGE_VIEW]: (params) => {
    const pageMeta = trackPageMeta({
      gaProperty: GA_PROPERTY,
      pageType: params.pageType,
      userId: getUserId() || undefined,
      clientId: getClientId() || undefined,
      environment: getConfig().env === STAGING_ENV ? STAGING_ENV : PRODUCTION_ENV,
      staging: getConfig().env === STAGING_ENV,
      selectedLanguage: getConfig().locale,
    });
    const pageView = trackPageView({
      page_type: params.pageType,
    });
    return [pageMeta, pageView];
  },
  [GtmEvent.LOGIN_ATTEMPT]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerLoginFlow',
      event_action: 'DealerLoginAttempt',
      page_type: PageType.LOGIN,
    },
  }),
  [GtmEvent.LOGIN_ERROR]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerLoginFlow',
      event_action: 'DealerLoginFail',
      page_type: PageType.LOGIN,
    },
  }),
  [GtmEvent.TAN_VALIDATION_ATTEMPT]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerLoginFlow',
      event_action: 'DealerLoginTanAttempt',
      page_type: PageType.TAN_VALIDATION,
    },
  }),
  [GtmEvent.TAN_VALIDATION_ERROR]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerLoginFlow',
      event_action: 'DealerLoginTanAttempt',
      page_type: PageType.TAN_VALIDATION,
    },
  }),
  [GtmEvent.PASSWORD_RESET_NAV]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerForgottenPasswordFlow',
      event_action: 'DealerForgottenPasswordBegin',
      page_type: PageType.LOGIN,
    },
  }),
  [GtmEvent.PROMO_NAV]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerLoginPage',
      event_action: 'InteralLinkClick',
      event_label: 'target="promo_page"',
      page_type: PageType.LOGIN,
    },
  }),
  [GtmEvent.REGISTRATION_NAV]: () => ({
    event: 'analytics_event',
    attributes: {
      event_category: 'DealerRegistrationFlow',
      event_action: 'InternalButtonClick',
      event_label: 'content="imprint_creation"',
      page_type: PageType.LOGIN,
    },
  }),
};

export const initTrackking = () => {
  initialize([new GoogleTagManager(eventsMap)]);
};

